import { Fragment } from "react";
import { Menu, Transition, Disclosure } from "@headlessui/react";
import Container from "../../components/Container";
import cx from "clsx";
import { Link } from "@reach/router"
import { motion } from "framer-motion"

import { ChevronDownIcon } from "@heroicons/react/24/solid";

export default function Navbar(props) {
    const leftmenu = [
        {
            label: "Home",
            href: "/"
        },
        {
            label: "About",
            href: "/about"
        }
    ];

    const rightmenu = [
        {
            label: "Archive",
            href: "/archive"
        },

        {
            label: "Contact",
            href: "/contact"
        }
    ];

    const mobilemenu = [...leftmenu, ...rightmenu];

    return (
        <div className={'z-10 w-full text-green-950 amatic-sc-bold'}>
            <Container>
                <motion.div
                    initial={{ y: -150 }}
                    animate={{ y: 0 }}
                    transition={{ ease: "easeIn", duration: .5 }}>

                    <nav>
                        <Disclosure>
                            {({ open }) => (
                                <>
                                    <div className="flex flex-wrap justify-between pt-4 md:pt-10">
                                        <div className=" hidden w-full items-center justify-start md:flex md:w-auto md:flex-1 md:justify-end">
                                            {leftmenu.map((item, index) => (
                                                <Fragment key={`${item.label}${index}`}>
                                                    {item.children && item.children.length > 0 ? (
                                                        <DropdownMenu
                                                            menu={item}
                                                            key={`${item.label}${index}`}
                                                            items={item.children}
                                                        />
                                                    ) : (
                                                        <div
                                                            href={item.href}
                                                            key={`${item.label}${index}`}
                                                            className="px-5 py-2 text-3xl font-extrabold text-white-600 hover:text-lime-700 dark:text-white-400"
                                                            target={item.external ? "_blank" : ""}
                                                            rel={item.external ? "noopener" : ""}>
                                                            {item.label}
                                                        </div>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </div>
                                        <div className="flex flex-1 text-center flex justify-center ">
                                            <Link to="/" className="p-4 md:p-0 text-4xl md:text-6xl flex-col ">
                                                <div className="pr-2 md:pr-0 ">
                                                    Goldfinch
                                                </div>
                                                <div className="  ">
                                                     Acres
                                                </div>
                                            </Link>
                                            <Disclosure.Button
                                                aria-label="Toggle Menu"
                                                className="absolute z-10 right-10 top-10 ml-auto rounded-md px-2 py-1 text-white-500 focus:text-blue-500 focus:outline-none dark:text-white-300 md:hidden w-14">
                                                <svg
                                                    className="h-6 w-6 fill-current"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24">
                                                    {open && (
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                                                        />
                                                    )}
                                                    {!open && (
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                                                        />
                                                    )}
                                                </svg>
                                            </Disclosure.Button>
                                        </div>

                                        <div className=" hidden w-full flex-col items-center justify-start  md:flex md:w-auto md:flex-1 md:flex-row">
                                            {rightmenu.map((item, index) => (
                                                <Fragment key={`${item.label}${index}`}>
                                                    {item.children && item.children.length > 0 ? (
                                                        <DropdownMenu
                                                            menu={item}
                                                            key={`${item.label}${index}`}
                                                            items={item.children}
                                                        />
                                                    ) : (
                                                        <div
                                                            href={item.href}
                                                            key={`${item.label}${index}`}
                                                            className="px-5 py-2 text-3xl font-extrabold hover:text-lime-700 dark:text-white-400"
                                                            target={item.external ? "_blank" : ""}
                                                            rel={item.external ? "noopener" : ""}>
                                                            <span> {item.label}</span>
                                                            {item.badge && (
                                                                <span className="ml-2 rounded bg-blue-100 px-2 py-0.5 text-xs font-semibold text-blue-600 dark:bg-cyan-200 dark:text-blue-800 ">
                                                      {item.badge}
                                                    </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </div>
                                    </div>

                                    <Disclosure.Panel>
                                        <div className="absolute top-0 pt-36 left-0 px-10 order-2 flex w-full flex items-center justify-start md:hidden">
                                            {mobilemenu.map((item, index) => (
                                                <Fragment key={`${item.label}${index}`}>
                                                    {item.children && item.children.length > 0 ? (
                                                        <DropdownMenu
                                                            menu={item}
                                                            key={`${item.label}${index}`}
                                                            items={item.children}
                                                            mobile={true}
                                                        />
                                                    ) : (
                                                        <div
                                                            href={item.href}
                                                            key={`${item.label}${index}`}
                                                            className=" px-5 py-2 text-center w-full text-2xl  text-white-600 hover:text-lime-700 dark:text-white-400"
                                                            target={item.external ? "_blank" : ""}
                                                            rel={item.external ? "noopener" : ""}>
                                                            {item.label}
                                                        </div>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </nav>
                </motion.div>

            </Container>
        </div>

    );
}

const DropdownMenu = ({ menu, items, mobile }) => {
    return (
        <Menu
            as="div"
            className={cx("relative text-left", mobile && "w-full")}>
            {({ open }) => (
                <>
                    <Menu.Button
                        className={cx(
                            "flex items-center gap-x-1 rounded-md px-5 py-2 text-sm font-medium  outline-none transition-all focus:outline-none focus-visible:text-indigo-500 focus-visible:ring-1 dark:focus-visible:bg-white-800",
                            open
                                ? "text-blue-500 hover:text-lime-700"
                                : " text-white-600 dark:text-white-400 ",
                            mobile ? "w-full px-4 py-2 " : "inline-block px-4 py-2"
                        )}>
                        <span>{menu.label}</span>
                        <ChevronDownIcon className="mt-0.5 h-4 w-4" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="lg:transition lg:ease-out lg:duration-100"
                        enterFrom="lg:transform lg:opacity-0 lg:scale-95"
                        enterTo="lg:transform lg:opacity-100 lg:scale-100"
                        leave="lg:transition lg:ease-in lg:duration-75"
                        leaveFrom="lg:transform lg:opacity-100 lg:scale-100"
                        leaveTo="lg:transform lg:opacity-0 lg:scale-95">
                        <Menu.Items
                            className={cx(
                                "z-20 origin-top-left rounded-md  focus:outline-none  lg:absolute lg:left-0  lg:w-56",
                                !mobile && "bg-white shadow-lg  dark:bg-white-800"
                            )}>
                            <div className={cx(!mobile && "py-3")}>
                                {items.map((item, index) => (
                                    <Menu.Item as="div" key={`${item.title}${index}`}>
                                        {({ active }) => (
                                            <div
                                                href={item?.path ? item.path : "#"}
                                                className={cx(
                                                    "flex items-center space-x-2 px-5 py-2 text-sm lg:space-x-4",
                                                    active
                                                        ? "text-blue-500"
                                                        : "text-white-700 hover:text-lime-700 focus:text-blue-500 dark:text-white-300"
                                                )}>
                                                <span> {item.title}</span>
                                            </div>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};
