import { Router, Link } from "@reach/router"
import {
    Home,
    Dashboard
} from "../pages"

import {
    Navbar
} from "../components"

function App() {
  return (
      <div className={"antialiased text-gray-800 dark:text-gray-400"}>
          <Navbar />
          <Router>
                <Home path="/" />
                <Dashboard path="dashboard" />
                <Home default />
          </Router>
      </div>

  );
}

export default App;
