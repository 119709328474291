import Container from "../Container";
import Card from "../Card";
import { motion } from "framer-motion"

import farmView from'../../images/farm_view.jpg';
import bees from'../../images/beehive.jpg';
import chickens from'../../images/chickens.jpg';


function Hero() {
    return (
        <>
            <div className={' top-0 z-0 w-full h-screen'}>
                <Container large={true}>
                    <motion.div
                        className={'grow w-full h-screen'}
                        initial={{ y: 20 }}
                        animate={{ y: 0 }}
                        transition={{ ease: "easeIn", duration: .5 }}>
                        <Card background={farmView}>
                        </Card>
                    </motion.div>
                    <motion.div
                        className={'grow w-full h-96'}
                        initial={{ y: 20 }}
                        animate={{ y: 0 }}
                        transition={{ ease: "easeIn", duration: .6 }}>
                        <Card background={chickens}>
                        </Card>

                    </motion.div>
                    <motion.div
                        className={'grow w-full h-96'}

                        initial={{ y: 20 }}
                        animate={{ y: 0 }}
                        transition={{ ease: "easeIn", duration: .7 }}>
                        <Card background={bees}>
                        </Card>

                    </motion.div>
                </Container>
            </div>

        </>
    );
}

export default Hero;
